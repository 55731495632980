<template>
  <v-dialog
    v-model="alwaystrue"
    max-width="325"
    :timeout="-1"
    overlay-color="#09212b"
    overlay-opacity="1"
  >
    <v-card style="border-radius: 20px !important" flat class="pa-0">
      <v-toolbar flat dense>
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('close')"><v-icon>mdi-close</v-icon></v-btn>
      </v-toolbar>
      <v-card-text
        v-if="errorMessage"
        class="Text-Dark-Text-1---18-Center mt-4"
      >
        {{ errorMessage }}
      </v-card-text>
      <v-container class="text-center">
        <v-row>
          <v-col cols="12">
            <v-btn
              @click="$emit('acknowledge')"
              min-height="48"
              min-width="150"
              class="gradient white--text Text-Dark-Text-1---18-Center"
              >{{ $t("profile.ok") }}</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "ErrorDialog",
  data: () => ({
    alwaystrue: true,
  }),
  props: ["errorMessage"],
};
</script>
