<template>
  <v-container>
    <v-img
      :src="require('@/assets/reservation_bg.png')"
      contain
      style="position: absolute; top: 0; right: 0"
    ></v-img>
    <back-toolbar title="detail.reserveTable"></back-toolbar>
    <v-row>
      <v-col cols="12">
        <v-card elevation="0" style="background: transparent">
          <v-card-title
            class="HeadingDarkH3-22Center"
            style="word-break: break-word"
          >
            {{
              $t("reservation.foundOptions", {
                date: $store.state.reserveout.date,
                time: $store.state.reserveout.time,
                covers: $store.state.reserveout.covers,
                header: $store.state.reserveout.header,
              })
            }}
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card elevation="0">
          <template v-for="(area, index) in availability">
            <div
              :key="`header-${index}`"
              class="Heading-Dark-H4-18-Left-Align ml-1 mt-3"
            >
              {{ area[0].areaName }}
            </div>
            <v-divider :key="`divider-${index}`"></v-divider>
            <template v-for="(item, areaIndex) in area">
              <v-btn
                v-if="item.previous"
                :key="`previous-${areaIndex}-${item.areaName}`"
                :ripple="false"
                style="border-radius: 12px; width: 22%"
                outlined
                class="px-5 mx-1 mt-2 Heading-Dark-H6-14-Center"
                :class="item.previous.selected ? 'gradient white--text' : ''"
                @click="selectPrevious(index, areaIndex)"
                >{{
                  item.previous.time[0] +
                  ":" +
                  item.previous.time[1].toLocaleString("en-US", {
                    minimumIntegerDigits: 2,
                    useGrouping: false,
                  })
                }}</v-btn
              >
              <v-btn
                v-if="item.current"
                :key="`current-${areaIndex}-${item.areaName}`"
                :ripple="false"
                style="border-radius: 12px; width: 22%"
                outlined
                class="px-5 mx-1 mt-2 Heading-Dark-H6-14-Center"
                :class="item.current.selected ? 'gradient white--text' : ''"
                @click="selectCurrent(index, areaIndex)"
                >{{
                  item.current.time[0] +
                  ":" +
                  item.current.time[1].toLocaleString("en-US", {
                    minimumIntegerDigits: 2,
                    useGrouping: false,
                  })
                }}</v-btn
              >
              <v-btn
                v-if="item.next"
                :key="`next-${areaIndex}-${item.areaName}`"
                :ripple="false"
                style="border-radius: 12px; width: 22%"
                outlined
                class="px-5 mx-1 mt-2 Heading-Dark-H6-14-Center"
                :class="item.next.selected ? 'gradient white--text' : ''"
                @click="selectNext(index, areaIndex)"
                >{{
                  item.next.time[0] +
                  ":" +
                  item.next.time[1].toLocaleString("en-US", {
                    minimumIntegerDigits: 2,
                    useGrouping: false,
                  })
                }}</v-btn
              >
            </template>
          </template>
        </v-card>
        <v-card
          style="position: absolute; bottom: 60px; width: 100%; left: 0"
          elevation="0"
        >
          <v-card-actions>
            <v-row justify="space-around">
              <v-btn
                height="48"
                min-width="150"
                class="gradient Heading-White-H4-18-Left-Align"
                dark
                depressed
                :loading="$store.state.reserveout.status === 'fetching'"
                @click="confirmReservation"
                :disabled="!selectedTime"
                >{{ $t("reservation.confirm") }}</v-btn
              >
              <v-btn
                height="48"
                min-width="150"
                class="gradient Heading-White-H4-18-Left-Align"
                dark
                depressed
                @click="$router.back()"
                >{{ $t("reservation.cancel") }}</v-btn
              >
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <booking-confirmation
      v-if="bookingConfirmation"
      @done="
        (bookingConfirmation = false),
          $router.safePush({
            name: 'home',
          })
      "
      @modify="modifyBooking()"
      @cancel="cancelDialog = true"
    />

    <cancel-booking-dialog
      v-if="cancelDialog"
      @close="cancelDialog = false"
      @cancelConfirmed="cancelReservation"
    />

    <booking-canceled-dialog
      v-if="bookingCanceledDialog"
      @close="bookingCanceled()"
    />

    <complete-profile
      v-if="completeProfile"
      @close="completeProfile = false"
      @completed="completeProfile = false"
    />

    <please-log-in-dialog
      v-if="pleaseLogInDialog"
      :email="profile.email"
      @close="
        (pleaseLogInDialog = false),
          $router.safePush({
            name: 'loginEmail',
          })
      "
    />

    <error-dialog
      v-if="errorDialog"
      :errorMessage="errorMessage"
      @acknowledge="acknowledgeError()"
      @close="acknowledgeError()"
    />
  </v-container>
</template>
<script>
import BackToolbar from "@/components/app/common/BackToolbar";
import groupBy from "lodash/groupBy";
import CompleteProfile from "@/components/profile/CompleteProfile";
import CancelBookingDialog from "@/components/app/bookings/CancelBookingDialog";
import BookingCanceledDialog from "@/components/app/bookings/BookingCanceledDialog";
import BookingConfirmation from "@/components/app/bookings/BookingConfirmation";
import ErrorDialog from "@/components/app/common/ErrorDialog";
import PleaseLogInDialog from "@/components/app/bookings/PleaseLogInDialog";
import Vue from "vue";

export default {
  data: () => ({
    date: new Date(),
    completeProfile: false,
    cancelDialog: false,
    showError: false,
    selectedTime: "",
    bookingCanceledDialog: false,
    pleaseLogInDialog: false,
    modify: false,
  }),
  components: {
    BackToolbar,
    CompleteProfile,
    CancelBookingDialog,
    BookingCanceledDialog,
    BookingConfirmation,
    ErrorDialog,
    PleaseLogInDialog,
  },
  beforeDestroy() {
    this.bookingConfirmation = false;
  },
  beforeMount() {
    if (this.$router.from.name === "modifyBooking") {
      if (this.$router.action === "back") {
        this.$router.safePush({ name: "home" });
      } else {
        this.modify = true;
      }
    }
  },
  computed: {
    errorDialog: {
      get() {
        return this.$store.state.reserveout.status === "failure";
      },
      set(val) {
        this.$store.commit("reserveout/RESET_ERROR");
      },
    },
    errorMessage() {
      switch (this.$store.state.reserveout.error) {
        case "ParseError: INVALID_COUNTRY":
        case "Invalid phone number":
          return Vue.i18n.translate("reservation.badPhoneNumber");
        default:
          return this.$store.state.reserveout.error;
      }
    },
    profile() {
      return this.$store.state.profile.data;
    },
    bookingConfirmation: {
      get() {
        return this.$store.state.reserveout.bookingConfirmation;
      },
      set(val) {
        this.$store.commit("reserveout/SHOW_BOOKING_CONFIRMATION", val);
      },
    },
    availability() {
      return groupBy(this.$store.state.reserveout.available, "areaName");
    },
  },
  methods: {
    groupBy,
    selectPrevious(index, areaIndex) {
      this.deselectTime();
      this.availability[index][areaIndex].previous.selected = true;
      this.areaId = this.availability[index][areaIndex].previous.areaId;
      this.selectedTime =
        this.availability[index][areaIndex].previous.time[0] +
        ":" +
        this.availability[index][areaIndex].previous.time[1].toLocaleString(
          "en-US",
          {
            minimumIntegerDigits: 2,
            useGrouping: false,
          }
        );
    },
    selectCurrent(index, areaIndex) {
      this.deselectTime();
      this.availability[index][areaIndex].current.selected = true;
      this.areaId = this.availability[index][areaIndex].current.areaId;
      this.selectedTime =
        this.availability[index][areaIndex].current.time[0] +
        ":" +
        this.availability[index][areaIndex].current.time[1].toLocaleString(
          "en-US",
          {
            minimumIntegerDigits: 2,
            useGrouping: false,
          }
        );
    },
    selectNext(index, areaIndex) {
      this.deselectTime();
      this.availability[index][areaIndex].next.selected = true;
      this.areaId = this.availability[index][areaIndex].next.areaId;
      this.selectedTime =
        this.availability[index][areaIndex].next.time[0] +
        ":" +
        this.availability[index][areaIndex].next.time[1].toLocaleString(
          "en-US",
          {
            minimumIntegerDigits: 2,
            useGrouping: false,
          }
        );
    },
    confirmReservation() {
      // Force use of updated profile data.
      let profile = this.$store.state.profile.data;
      if (this.$store.state.isMiKY && !this.$store.state.session.user) {
        this.$store
          .dispatch("profile/userExists", {
            email: profile.email,
          })
          .then(() => {
            if (this.$store.state.profile.userExists) {
              this.pleaseLogInDialog = true;
            } else {
              this.completeProfile = true;
            }
          });
        return;
      }
      if (profile.firstName && profile.lastName && profile.phone) {
        this.$store.dispatch("reserveout/postReservation", {
          email: profile.email,
          name: profile.firstName + " " + profile.lastName,
          countryId: this.$store.state.reserveout.countryId,
          phone: profile.phone,
          placeId: this.$store.state.reserveout.placeId,
          recommendationId: this.$store.state.recommendations.details.id,
          areaId: this.areaId,
          covers: this.$store.state.reserveout.covers,
          date: this.$store.state.reserveout.date,
          time: this.selectedTime,
          waitingList: true,
          action: this.modify ? "modify" : "reserve",
          visitId: this.modify
            ? this.$store.state.reserveout.visitId
            : undefined,
          city: this.$store.state.recommendations.details.city,
        });
      } else {
        this.completeProfile = true;
      }
    },
    cancelReservation() {
      this.$store.dispatch("reserveout/cancelReservation", {
        visitId: this.$store.state.reserveout.reserved.visitId,
      });
      this.cancelDialog = false;
      this.bookingCanceledDialog = true;
    },
    bookingCanceled() {
      this.bookingCanceledDialog = false;
      this.$router.safePush({ name: "home" });
    },
    deselectTime() {
      if (!this.availability) return;

      Object.keys(this.availability).forEach((el) =>
        this.availability[el].forEach((subEl) => {
          if (subEl.next && subEl.next.selected) subEl.next.selected = false;
          if (subEl.current && subEl.current.selected)
            subEl.current.selected = false;
          if (subEl.previous && subEl.previous.selected)
            subEl.previous.selected = false;
        })
      );
      // same time in a different area are not able to be selected without this
      this.$forceUpdate();
    },
    modifyBooking() {
      this.bookingConfirmation = false;
      this.$store.commit("reserveout/SET_PARAMS", {
        placeId: this.$store.state.reserveout.placeId,
        covers: this.$store.state.reserveout.covers,
        date: this.$store.state.reserveout.date,
        time: this.$store.state.reserveout.time,
        header: this.$store.state.reserveout.reserved.restaurant.name
          ? this.$store.state.reserveout.reserved.restaurant.name
          : this.$store.state.reserveout.header,
        areaId: this.$store.state.reserveout.reserved.area.id,
        countryId: this.$store.state.reserveout.countryId,
        visitId: this.$store.state.reserveout.reserved.visitId,
      });
      this.$router.safePush({
        name: "modifyBooking",
        params: {
          placeId: this.$store.state.reserveout.placeId,
          visitId: this.$store.state.reserveout.reserved.visitId,
        },
      });
    },
    acknowledgeError() {
      switch (this.$store.state.reserveout.error) {
        case "ParseError: INVALID_COUNTRY":
        case "Invalid phone number":
          this.completeProfile = true;
          this.errorDialog = false;
          break;
        default:
          this.errorDialog = false;
          break;
      }
    },
  },
};
</script>
<style scoped>
/deep/ .v-btn--outlined {
  border: 2px solid #9da6aa;
}
/deep/ .profile-sheet {
  border-radius: 30px 30px 0 0 !important;
}
</style>
